/* Search and Filter Component for Trade Request Page */
.search_container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px 20px 0px;
  border: 0.5px solid grey;
  border-radius: 10px;
  padding: 20px;
}

.filter,
.search {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input,
select {
  width: 250px;
  border: 1px solid grey;
  border-radius: 10px;
  outline: none;
  padding: 7px 10px;
}

/* Single Trade Information Styles */
.release_funds {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.release__btn {
  font-size: 14px;
  padding: 7px 20px 7px 20px;
  border-radius: 5px;
  background-color: #252063;
  color: white;
  outline: none;
}

/* File Uploader Component */

.file-upload-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.file-upload-label {
  font-size: 14px;
  color: #444;
  margin-bottom: 20px;
}

.file-upload-dropzone {
  border: 2px dashed #252063;
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-upload-dropzone:hover {
  background-color: #f9f9f9;
}

.file-upload-input {
  display: none;
}

.file-upload-content {
  color: #888;
}

.upload-icon {
  font-size: 24px;
  color: #252063;
  margin-bottom: 10px;
}

.file-upload-choose {
  color: #252063;
  font-weight: 500;
  cursor: pointer;
}

.file-upload-format {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
}

.file-upload-selected {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}

.delete-button {
  margin-left: 10px;
  font-size: 20px;
  color: #d9534f;
  cursor: pointer;
}

.file-upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.file-upload-button > button {
  font-weight: bold;
  display: block;
  border: 1px solid #252063;
  border-radius: 5px;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.file-upload-button > div > p {
  color: #252063;
  font-size: 15px;
  font-weight: 600;
}
